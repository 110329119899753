/**
 * Кастомизация mat-tooltip.
 */

@import "../variables/global.scss";

.mat-tooltip {
  font-size: $small-text-size;
  word-wrap: break-word;

  &.white-custom-mat-tooltip {
    width: 225px;
    padding: 10px;
    background-color: $white-color;
    color: $light-gray-color;
    white-space: break-spaces;
    border-radius: 8px;
    line-height: 18px;
    font-weight: 500;
    box-shadow: 0 0 10px 0 rgba(90, 90, 90, 0.15);
  }
}
