@import "../variables/global";

.entera .mat-progress-bar {

    & .mat-progress-bar-buffer {
        background-color: $very-light-gray-color;
    }

    &_thin {
        height: 2px;
    }

    & .mat-progress-bar-fill {
        transition: none
    }
}
