/**
 * Кастомизация mat-dialog.
 */

@import "../variables/global.scss";

.entera {
  .mat-dialog-container {
    position: relative;
    color: $text-color;
    padding: $dlg-default-padding;
  }

  .entera-simple-dlg .mat-dialog-container {
    padding-bottom: 0;
  }

  .entera-error-details-panel {
    .mat-dialog-container {
      padding: 0;

      .server-side-error-details .error-details__text a {
        color: $green-color;
        font-weight: 500;
      }
    }
  }

  .dlg-pane {
    max-height: 87vh;
  }

  .mat-dialog-title {
    margin: 0;
    padding-bottom: 40px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    text-align: center;
  }

  .mat-dialog__cross-close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    min-width: 36px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: transparent !important;
    box-shadow: none !important;

    .mat-button-wrapper {
      display: inline-flex;
      padding: 0;
    }
  }

  .mat-dialog-actions {

    .mat-dialog__action-btn + .mat-dialog__action-btn {
      margin-left: 10px;
    }
  }

  .mat-dialog-action {
    margin-top: 15px;
    margin-bottom: -25px;
  }
  .mat-dialog-actions {
    padding: $dlg-default-padding 0 0;
    margin: 0;
    justify-content: flex-end;
    min-height: 0;
  }
}
