@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~handsontable/dist/handsontable.full.css";
@import "variables/global.scss";
@import "mixins";

//region Fonts

@import "fonts";

//endregion
//region Common

html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  display: flex;
  min-height: 100%;
  background-color: #f8f8f8;
  color: $text-color;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: $medium-text-size;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding-right: $default-padding;
  padding-left: $default-padding;
}

.fluid-container {
  margin: 0;
  padding-right: $default-padding;
  padding-left: $default-padding;
}

.loading-spinner-container {
  position: relative;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  & mat-spinner {
    margin: auto;
  }
}

.text-center {
  text-align: center;
}

.fit-content-height {
  min-height: fit-content;
}

//endregion
//region Header

.entera .with-header {
  padding-top: ($header-height + 1) * 1px;
}

//endregion
//region Input styles

.disabled-like-block {
  & .mat-input-element:disabled {
    color: inherit;
  }
  & .mat-form-field-underline {
    visibility: hidden;
  }

  & mat-select .mat-select-value {
    display: inline;
    color: inherit;
  }

  & mat-select .mat-select-arrow-wrapper {
    display: none;
  }
}

//endregion
//region Badges

.fixed-badge {
  & .mat-badge-content {
    width: auto !important;
    padding: 0 16px !important;
    border-radius: 15px;
  }
}

//endregion
//region Material customizations

@import "./material-customizations/mat-badge.scss";
@import "./material-customizations/mat-card.scss";
@import "./material-customizations/mat-datepicker.scss";
@import "./material-customizations/mat-dialog.scss";
@import "./material-customizations/mat-h.scss";
@import "./material-customizations/mat-icon.scss";
@import "./material-customizations/mat-menu.scss";
@import "./material-customizations/mat-progress-bar.scss";
@import "./material-customizations/mat-select.scss";
@import "./material-customizations/mat-tabs.scss";
@import "./material-customizations/mat-option.scss";
@import "./material-customizations/mat-tooltip.scss";
@import "./material-customizations/mat-slide-toggle.scss";
@import "./material-customizations/ngx-mat-select-search.scss";
@import "./material-customizations/overlay.scss";
@import "./material-customizations/mat-form-field.scss";
@import "./material-customizations/mat-form-field-short.scss";
@import "./material-customizations/cdk-virtual-scroll-viewport.scss";

//endregion
//region Component global styles

@import "./component-global-styles/balance-control-page.scss";
@import "./component-global-styles/error-details.scss";
@import "./component-global-styles/first-upload-dlg.scss";
@import "./component-global-styles/handson-table.scss";
@import "./component-global-styles/recognition-task-created-dlg.scss";
@import "./component-global-styles/recognition-task-error-dlg.scss";
@import "./component-global-styles/registration-form.scss";
@import "src/styles/component-global-styles/users-invitation-dlg-panel";
@import "src/styles/component-global-styles/broadcast-message-viewer-component";
//endregion
