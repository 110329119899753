@import "../variables/global";

.htCore {

  .htSerialNumberColumn {
    background: #f0f0f0;
    color: $text-color;
  }

  .htFirstPageRow {
    background: $light-green-color;
  }

  .htEndPageRow {
    border-bottom: 2px solid;
  }
}