/**
 * Кастомизация ngx-mat-select-search.
 */

.entera {

  .single-select-panel, .entera-select-panel {

    .mat-option {
      height: initial;
      min-height: 48px;
      white-space: normal;

      &.select__loading {

        .mat-option-text {
          display: flex;
          justify-content: center;
        }
      }

      &.select__invisible {
        height: 0;
        min-height: 0;
      }

      &.select__without-checkbox {

        mat-pseudo-checkbox {
          display: none;
        }
      }

      &.select__with-category {

        .mat-option-text {
          display: flex;
          justify-content: space-between;
        }
      }

      &:hover:not(.mat-option-disabled):not(.mat-selected),
      &:focus:not(.mat-option-disabled):not(.mat-selected),
      &.mat-active {
        background: rgba(0, 180, 130, 0.1);
      }

      .mat-option-text {
        padding: 10px 0;
        line-height: 20px;
        overflow: visible;
        text-overflow: initial;
      }

      .highlight {
        background: yellow;
      }
    }
  }

  .table-compact-select-panel {

    .mat-option {

      &.select__loading {

        .mat-option-text {
          display: flex;
          justify-content: center;

          .mat-spinner {
            width: 15px;
            height: 15px;
          }
        }
      }

      &.select__invisible {
        height: 0;
        min-height: 0;
        padding: 0;
      }

      &.select__without-checkbox {

        mat-pseudo-checkbox {
          display: none;
        }
      }

      &.select__with-category {

        .mat-option-text {
          display: flex;
          justify-content: space-between;
        }
      }

      .mat-option-text {
        padding: 10px 0;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .highlight {
        background: yellow;
      }
    }
  }
}
