/**
 * Кастомизация mat-tabs.
 */

@import "../variables/global.scss";

.entera {
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: none;
  }
  .mat-tab-link {
    color: $light-gray-color;
    font-weight: 400;
    opacity: 1;
  }
  .mat-tab-label-active {
    color: $text-color;
    font-weight: 500;
  }
}
