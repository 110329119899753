/**
 * Кастомизация mat-icon.
 */
@import "../variables/global.scss";

.entera {
  .mat-icon {
    height: $icon-size;
    width: $icon-size;
    font-size: $icon-size;
    line-height: $icon-size;
    color: $gray-color;
    text-align: center;

    &_large {
      height: $large-icon-size;
      width: $large-icon-size;
      font-size: $large-icon-size;
      line-height: $large-icon-size;

      &.mat-icon-button .mat-icon {
        height: 24px;
        width: 24px;
        font-size: 24px;
        line-height: 24px;
      }
    }

    &_red {
      color: $red-color;
    }

    &_green {
      color: $green-color;
    }

    &_font {
      color: $text-color;
    }
  }

  .mat-icon-button .mat-icon {
    position: relative;
    top: -1px;
    line-height: $icon-size;

    &[fontset] {
      top: -2px;
    }
  }

  .mat-raised-button,
  .mat-button {
    .mat-icon {
      margin-right: 6px;
      color: inherit;

      &.mat-icon_right {
        margin-right: 0;
        margin-left: 6px;
      }
    }
  }

  // Цвет иконок, при отображении их в overlay'е.
  .entera-panel {
    .mat-menu-item {
      &:hover {
        .mat-icon {
          &.mat-icon_red {
            color: $red-hover-color;
          }

          &.mat-icon_green {
            color: $green-hover-color;
          }
        }
      }
      &:active {
        .mat-icon {
          &.mat-icon_red {
            color: $red-action-color;
          }

          &.mat-icon_green {
            color: $green-action-color;
          }
        }
      }

      .mat-icon {
        color: $gray-color;

        &.mat-icon_red {
          color: $red-color;
        }

        &.mat-icon_green {
          color: $green-color;
        }
      }
    }
  }

  /**
     * Кастомизация иконки-кнопки для очистки компонента SingleSelectComponent.
     * При наведении на выпадашку появляется кнопка очистки значения.
     */
  .single-select {
    .mat-form-field-flex:hover {
      .single-select__clear-btn {
        display: block;
      }
    }
  }

  /**
   * Кастомизация иконки-кнопки для очистки компонента EnteraSelectComponent.
   * При наведении на выпадашку появляется кнопка очистки значения.
   */
  .entera-select {
    .mat-form-field-flex:hover {
      .entera-select__clear-btn {
        display: block;
      }
    }
  }
}
