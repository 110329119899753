/**
 * Кастомизация cdk-virtual-scroll-viewport.
 */

@import "src/styles/variables/global";

.entera {
  .cdk-virtual-scroll-viewport {
    &_matching-custom {
      .cdk-virtual-scroll-content-wrapper {
        width: 100%;
        margin-top: 80px;
      }
    }
  }
}
