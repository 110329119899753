@import "../variables/global.scss";

.balance-control-form {

  &__space-priority-flag {

    color: lightgray;
    font-weight: 600;

    &_active {

      color: $text-color;
      font-weight: 600;
    }
  }

  &__space-priority-flag + &__space-priority-flag {

    margin-left: 5px;
    margin-right: 10px;
  }
}
