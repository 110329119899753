/**
 * Кастомизация mat-badge.
 */

@import "../variables/global.scss";

// Отключение анимации.
.mat-badge.disable-animation .mat-badge-content {
    transition: none;
    -webkit-transform: none;
    transform: none;
}