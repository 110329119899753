@import "src/styles/variables/global.scss";

/**
 * Кастомизация mat-form-field.
 */
.entera {

  .entera-form-field-without-margin {

    display: block;
    margin-right: auto;
    margin-left: auto;

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      bottom: 0.12em;
    }

    .mat-form-field-infix {
      border-top: 0;
    }
  }

  .entera-form-field-outline-block {

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    &:hover {
      z-index: 20;
    }

    &:first-child {

      &.mat-stroked-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .mat-form-field-outline {

        .mat-form-field-outline-end {
          border-radius: 0;
        }
      }
    }

    &:last-child {

      &.mat-stroked-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .mat-form-field-outline {

        .mat-form-field-outline-start {
          border-radius: 0;
        }
      }
    }

    &:not(:first-child):not(:last-child) {

      .mat-form-field-outline-end, .mat-form-field-outline-start {
        border-radius: 0;
      }
    }

    &:not(:last-child) {
      margin-right: -1px;
    }
  }

  .entera-form-field-background-color-white {

    &.mat-form-field-appearance-outline .mat-form-field-outline {
      background-color: $white-color;
    }
  }

  .mat-table-compact {

    :focus-within > .mat-form-field-label-wrapper {
      display: none;
    }

    .mat-form-field-wrapper {
      padding: 0;

      .mat-form-field-flex {

        .mat-form-field-infix {

          border-top-width: 0;

          .mat-form-field-label-wrapper {
            top: -0.5em;
            padding-top: 0.5em;
          }

          .mat-form-field-label-wrapper .mat-form-field-label:not(.mat-form-field-empty) {
            display: none;
          }
        }
      }

      .mat-form-field-underline {
        bottom: 3px;
        background-color: transparent;
      }
    }
  }

  .marked-select {
    .mat-form-field-wrapper {
      .mat-form-field-underline {
        height: 1px;
        background-color: #ffb02e;
      }
    }
  }
}
