@import "src/styles/variables/global.scss";

/**
 * Кастомизация mat-slide-toggle.
 */
.entera {

  .mat-slide-toggle-light {

      .mat-slide-toggle-bar {
        width: 22px !important;
        height: 12px !important;
        border: 2px solid $text-color;
        border-radius: 36px !important;
        background-color: transparent;
      }

      .mat-slide-toggle-thumb {
        position: relative;
        top: 4px;
        left: 2px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $text-color;
      }

      .mat-slide-toggle-ripple {
        display: none;
      }
  }

  .mat-slide-toggle-light.mat-checked .mat-slide-toggle-bar {
    border-color: $green-color;
    background-color: transparent;
  }

  .mat-slide-toggle-light.mat-checked .mat-slide-toggle-thumb {
    left: -6px;
    background-color: $green-color;
  }

}