/**
 * Кастомизация mat-select.
 */

@import "../variables/global.scss";

.entera {

  .table-compact-select-panel {
    min-width: 288px;
    max-width: 689px;

    .mat-option {
      height: 32px;
      min-height: 32px;
      padding-left: 7px;
      white-space: normal;

      .mat-option-text {
        padding: 7px 8px;
        overflow: visible;
        font-size: 14px;
        line-height: 18px;
        text-overflow: initial;
      }
    }
  }

  .mat-option,
  .mat-select-value {
    color: $text-color;
  }
  .mat-select-value {
    font-size: $large-text-size;
  }

  .mat-form-field-disabled {
    cursor: not-allowed;
    .mat-select-value {
      cursor: not-allowed;
    }
  }

  .mat-select-arrow-wrapper {
    .mat-select-arrow {
      color: $input-icon-color;
    }
    &:hover {
      .mat-select-arrow {
        color: $light-gray-color;
      }
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $border-color;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $light-gray-color;
  }
  .mat-form-field-should-float.mat-form-field-appearance-legacy .mat-form-field-label {
    font-size: 16px;
  }
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.6em 0;
  }
}

/**
 * Кастомизация выпадашки для компонент SingleSelectComponent и EnteraSelectComponent.
 * При наведении на выпадашку появляется кнопка очистки значения.
 */
.single-select, .entera-select {
  .mat-form-field-flex {
    .mat-select-value {
      padding-right: 19px;
    }
  }
}

.mat-table-compact {
  .mat-form-field-flex {
    .mat-select-value {
      font-size: 14px;
    }

    .mat-form-field-infix {
      padding: 0.8em 0;
    }
  }
}

.mat-table-with-rows-group {
  .mat-form-field-flex {

    .mat-form-field-infix {
      min-height: 28px;
      align-content: end;
      padding: 0.4em 0;
    }
  }
}
