@import '../variables/global';

.entera {

    & .mat-h1 {

        margin-bottom: $default-margin;

        &_slim {
            font-weight: 300;
        }

        & .mat-icon {
            height: $medium-header-size;
            width: $medium-header-size;
            font-size: $medium-header-size;
            line-height: $medium-header-size;
            position: relative;
            top: 3px;
        }
    }


}
