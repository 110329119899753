@import "../variables/global.scss";

.registration-form {

  &__privacy-note {

    a {
      color: $text-color;
    }
  }
}
