.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {

  &.cdk-overlay-backdrop_dark {
    background: #000;
    opacity: 0.4;
  }
}

.cdk-overlay-pane {

  .mat-menu-panel {
    max-width: 500px;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.cdk-overlay-pane-select-search {

    &.cdk-overlay-pane-select-search-with-offset {
      margin: 0;
      transform: translateX(-16px) translateY(-13px) !important;
    }
  }
}