/**
 * Кастомизация mat-datepicker.
 */

@import "../variables/global.scss";

.entera {

  .entera-date-picker {

    &.mat-focused {

      .mat-datepicker-toggle {
        color: $green-color;
      }
    }

    .mat-datepicker-toggle {
      color: $input-icon-color;
    }
    
    .mat-datepicker-toggle-active {
      color: $green-color;
    }

    .mat-icon-button.cdk-focused {
      color: $green-color;
    }

    .mat-datepicker-toggle-default-icon {
      position: relative;
      top: -1px;
    }

    .mat-form-field-suffix {
      position: relative;
      left: 1px;
    }

    .mat-form-field-infix {
      height: 18px;
      padding: 0.7em 0 0.5em;

      .mat-input-element {
        font-size: $large-text-size;
      }
    }
  }
}
