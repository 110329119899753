//region Common

$background-color: #f8f8f8;

// Основной цвет текста.
$text-color: #2d2d2d;

// Размеры шрифта текста. 
$small-text-size: 12px;
$medium-text-size: 14px;
$large-text-size: 16px;
$medium-header-size: 24px;

// Цвета.
$white-color: #fff;
$white-hover-color: #fafafa;
$white-action-color: #f5f5f5;

$gray-color: #868686;
$light-gray-color: #8c8c8c;
$very-light-gray-color: #f0f0f0;

$green-color: #00b482;
$green-hover-color: #00af7d;
$green-action-color: #00af7d;

$light-green-color: #e5f7f2;

$red-color: #f44336;
$red-hover-color: #e63c32;
$red-action-color: #e63c32;
$light-red-color: rgba(255, 228, 226, 1);

$yellow-color: rgba(244, 168, 54, 1);

$light-yellow-color: #fff2db;

$input-icon-color: #bebebe;

$border-color: #e0e0e0;
$light-border-color: #f0f0f0;
$border-focus-color:#00b482;

$box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
$mat-card-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

// Высота заголовка сайта.
$header-height: 56;
$notification-height: 40;

$default-padding: 32px;
$default-margin: 32px;
$input-error-area-height: 1.25em;

// Отступы контента диалогов.
$dlg-default-padding: 40px;

//endregion
//region Material

// Размер иконок mat-icon.
$icon-size: 18px;
$large-icon-size: 28px;

// Высота элемента в mat-menu.
$mat-menu-item-height: 40px;

// Ширина диалога или карточек.
$mat-card-width: 384px;

//endregion
