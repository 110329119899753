/**
 * Глобальные стили компонента users-invitation-dlg.
 */

.users-invitations-dlg-panel {
  max-width: 600px !important;
  max-height: none !important;
  min-width: 600px;
  min-height: 100px !important;
}
