/**
 * Кастомизация ngx-mat-select-search.
 */

.entera {

  .single-select-panel, .entera-select-panel {

    // Корректно позиционируем блок, т.к. он немного наезжает на первое поле.
    ngx-mat-select-search {
      display: block;
      position: relative;
      top: -4px;
      height: 48px;

      .mat-select-search-no-entries-found {
        position: relative;
        top: 4px;
      }

      .mat-select-search-inner {
        border-bottom-color: rgba(0, 0, 0, 0.12);
      }

      // Убираем кнопку очистки поля поиска.
      .mat-select-search-clear {
        display: none;
      }

      // Расширяем поле поиска, т.к. удалили кнопку.
      .mat-select-search-input {
        padding: 20px 16px 12px;
      }
    }
  }

  .table-compact-select-panel {

    ngx-mat-select-search {

      .mat-select-search-input {
        padding: 7px 8px 7px 30px;
      }

      .mat-select-search-inner {
        border-bottom-color: rgba(0, 0, 0, 0.12);
      }

      .mat-select-search-inner:before {
        position: absolute;
        font-family: "Material Icons";
        font-size: 20px;
        color: #999999;
        content: "\e8b6";
        margin: 5px;
      }

      .mat-select-search-clear {
        top: -6px;

        .mat-button-wrapper {
          font-size: 12px;

          mat-icon {
            width: 12px;
            height: 12px;
            top: 2px;
            font-size: 12px;
            line-height: 12px;
            margin-right: 0;
          }
        }

        .mat-ripple {
          width: 30px;
          height: 30px;
          top: 8px;
          left: 4px;
          font-size: 12px;
        }

        .mat-button-focus-overlay {
          width: 30px;
          height: 30px;
          top: 8px;
          left: 4px;
          font-size: 12px;
        }
      }
    }
  }
}
